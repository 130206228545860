import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'home', redirectTo: 'welcome', pathMatch: 'full' },
  // { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'story',
    loadChildren: () => import('./story/story.module').then( m => m.StoryPageModule)
  },
  {
    path: 'point1',
    loadChildren: () => import('./point1/point1.module').then(m => m.StoryPoint1PageModule)
  },
  // {
  //   path: 'welcome-modal',
  //   loadChildren: () => import('./welcome-modal/welcome-modal.module').then( m => m.WelcomeModalPageModule)
  // },
  {
    path: 'story-info',
    loadChildren: () => import('./story-info/story-info.module').then( m => m.StoryInfoPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then( m => m.StartPageModule)
  },  {
    path: 'point2',
    loadChildren: () => import('./point2/point2.module').then( m => m.Point2PageModule)
  },
  {
    path: 'point3',
    loadChildren: () => import('./point3/point3.module').then( m => m.Point3PageModule)
  },
  {
    path: 'point4',
    loadChildren: () => import('./point4/point4.module').then( m => m.Point4PageModule)
  },
  {
    path: 'point5',
    loadChildren: () => import('./point5/point5.module').then( m => m.Point5PageModule)
  },
  {
    path: 'point6',
    loadChildren: () => import('./point6/point6.module').then( m => m.Point6PageModule)
  },
  {
    path: 'point7',
    loadChildren: () => import('./point7/point7.module').then( m => m.Point7PageModule)
  },
  {
    path: 'jigsaw',
    loadChildren: () => import('./jigsaw/jigsaw.module').then( m => m.JigsawPageModule)
  },
  {
    path: 'finish',
    loadChildren: () => import('./finish/finish.module').then( m => m.FinishPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
