import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  // indicators for whether a flag has been clicked, i.e. whether a story point has been completed
  startClicked;
  point1Clicked;
  point2Clicked;
  point3Clicked;
  point4Clicked;
  point5Clicked;
  point6Clicked;
  point7Clicked;
  finishClicked;
  centreLocation;
pirateName;


  constructor() {
    this.centreLocation = {
      lat: 54.970675,
        lng: -1.599009
    };
  }
}
